@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
}

:root {
  --fc-button-text-color: #328f35 !important;
  --fc-button-bg-color: #fff !important;
  --fc-button-border-color: #328f35 !important;
  --fc-button-hover-bg-color: rgba(50, 143, 53, 0.2) !important;
  --fc-button-hover-border-color: #328f35 !important;
  --fc-button-active-bg-color: #328f35 !important;
  --fc-button-active-border-color: #328f35 !important;
  --fc-today-bg-color: #e8f1e8 !important;
}

html,
#root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Kanit", sans-serif;
  min-height: 100%;
  height: 100%;
}

.mention {
  color: #328f35;
  text-decoration: none;
}

.mention:hover {
  color: #105f18;
}

.date-picker,
.date-picker > div.react-datepicker-wrapper,
.date-picker
  > div
  > div.react-datepicker__input-container
  .date-picker
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

@page {
  size: A4;
  margin-bottom: 4rem;
}

/* @media print {
  body,
  html {
    width: 21cm !important;
    height: 29.7cm !important;
    margin: 0;
  }

  @page landscape {
    size: landscape;
  }

  .landscape {
    page: landscape;
  }
} */

.ag-watermark {
  display: none !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  color: #fff !important;
}

.fc-toolbar-chunk:nth-child(2) {
  display: flex;
  gap: 16px;
  align-items: center;
}

.fc .fc-toolbar-title {
  font-family: "Roboto", sans-serif;
  font-size: 1.25em;
  color: #328f35;
  /* text-decoration: underline; */
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0 !important;
}

.fc-timegrid-event .fc-event-main {
  overflow: hidden !important;
}

.fc-daygrid-event {
  /* border-radius: 16px; */
  border-color: #328f35 !important;
}

.fc .fc-toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 1rem;

  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }
}
.a4-margin {
  margin-top: 70px;
}

.a4-container {
  width: fit-content;
  margin: auto;
  margin-top: 100px;
}

.a4-page-break {
  margin-bottom: 20px;
}

.a4-label {
  /* font-size: 1px; */
  border: 1px solid black;
}

@media print {
  .print-button {
    display: none;
  }
  .a4-margin {
    display: none;
  }

  .print-button {
    visibility: hidden;
  }
  .a4-page * {
    visibility: visible;
  }

  .a4-container {
    margin: 0;
  }

  .a4-page-break {
    margin-bottom: 0;
  }

  .a4-label {
    border: none;
  }
}
